<template>
    <section v-if="siteSettings && siteSettings?.business_type==2"  class="help-and-support-box">
        <div class="support-sec">
            <a ref="whatsappModal" href="javascript:void(0);" @click="getParentWhatsappNumber" data-bs-toggle="modal" data-bs-target="#whatsup-depositModal" target="_blank">
                <img loading="lazy" src="@/assets/images/wp_support.webp" alt="">
            </a>
        </div>
    </section>

    <ChatComponent @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc"></ChatComponent>
    <!--Whatsup Deposit Modal -->
    <div class="modal fade whatsup-modal-wrapper" id="whatsup-depositModal" tabindex="-1" aria-labelledby="whatsupModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">{{translatedLangData('customer-support','Customer Support')}}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                        <i class="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div class="modal-body whatsup-modal-information">
                        <div class="whatsaap-support-btn-list" v-if="whatsappNumbers && whatsappNumbers.length > 0">
                        <div class="support-call-now" v-for="(data, index) in whatsappNumbers" :key="index">
                            <a :href="'https://wa.me/' + data.whatsapp_number" target="_blank">
                                <div class="support-whatsapp-icon"><img src="@/assets/images/whatsapp-icon-new.svg" alt="whatsapp"></div>
                                <span class="btn thm-btn">{{ data.category_name }}</span>
                            </a>      
                        </div>
                        </div>
                        <div v-else-if="siteSettings && (siteSettings.social_link?.whatsappno1 || siteSettings.social_link?.whatsappno2)">
                        <template v-for="number in [siteSettings.social_link?.whatsappno1, siteSettings.social_link?.whatsappno2]">
                            <a v-if="number" 
                                :key="number" 
                                :href="'https://wa.me/' + number" 
                                target="_blank" 
                                class="btn thm-btn call-now">
                                <img src="@/assets/images/whatsApp-icon.webp" alt="whatsapp">{{ number }}
                            </a>
                        </template>
                    </div>
                    <div v-else>
                        <p>{{translatedLangData('no-whatsapp-avail','No WhatsApp numbers available.')}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
<!--Whatsup Deposit Modal -->
</template>
<script>
import ChatComponent from '@/modules/chat/views/ChatComponent.vue';

export default {
    name: 'Footer',
    inject: ['translatedLangData'],
    components:{
        ChatComponent
    },
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings;
        },
        domainName() {
            return this.$store.getters.domainName;
        }
    },
    data() {
        return {
            whatsappNumbers:[],
        }
  },
  methods: {
    checkIsLogin() {
      return this.$store.getters.isAuthenticated;
    },
    async getParentWhatsappNumber() {
            if (this.checkIsLogin()) {
                this.loading = true;
                try {
                    const cpiExists = this.$store.getters.stateUser?.cpi;
                    const supportNumbers = this.siteSettings.support_numbers || [];
                    if (!cpiExists) {
                        return;
                    }

                    const now = new Date();
                    const totalMinutes = now.getHours() * 60 + now.getMinutes();

                    this.whatsappNumbers = supportNumbers
                        .filter(entry => entry.id === cpiExists)
                        .flatMap(entry => entry.categories.map(category => {
                            const whatsappNumbers = category.value.split(',');
                            const selectedNumberIndex = totalMinutes % whatsappNumbers.length;
                            return {
                                category_name: category.name,
                                whatsapp_number: whatsappNumbers[selectedNumberIndex]
                            };
                        }));
                    this.loading = false;
                } catch (error) {
                    this.loading = false;
                    console.log(error);
                    this.showErrorModalFunc(error[0]);
                }
            } else {
            }
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
  },
}
</script>