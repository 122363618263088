<template>
    <div class="login-model-pop-up-sec sign-up-wrapper">
        <div class="modal fade" id="register-btn" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
            data-bs-backdrop="static">
            <div class="modal-dialog">
                <div class="modal-content">
                    <!-- <button @click="refreshData()" type="button" class="btn-close" data-bs-dismiss="modal"
                        aria-label="Close"><i class="fa-solid fa-xmark"></i></button> -->
                    <div class="modal-body">
                        <div class="login-body-sec">
                            <div class="login-body-lft">
                                <!-- <div class="login-header">
                                        <h2 class="modal-title" id="exampleModalLabel">Register</h2>
                                    </div> -->
                                <div class="forget-login">
                                    <div class="login-now">
                                        <div class="login-flow-heading">
                                            <div class="web-logo">
                                                <img :src="siteSettings?.aws_url + siteSettings?.storage_path.domain_image + siteSettings?.logo" alt="logo">
                                            </div>
                                            <h1 class="login-form sign-up-h">{{ translatedLangData('register','Register')}}</h1>
                                        </div>
                                        <div id="msgFromServer"></div>


                                        <input type="hidden" id="csrf-token">
                                            <div class="number-var mak-gin sign-up-body">
                                                
                                                <div class="row g-0 sign-up-row">
                                                    <div class="col-12 col-sm-12 col-md-12">
                                                        <div class="contact-info-wrapper">
                                                            <div class="select-country-code">
                                                                <select v-model="form.code" class="form-select form-control codein valid" id="autoSizingSelect" :disabled="isOtpApiCalled">
                                                                    <option :value="allowedCountry.phonecode"
                                                                        v-for="(allowedCountry, countryIndex) in siteSettings?.country_code_list"
                                                                        :key="countryIndex" :disabled="allowedCountry.phonecode!='91'">+{{ allowedCountry.phonecode }}</option>
                                                                </select>
                                                            </div>
                                                            <div class="input-left">
                                                                <input type="tel" class="form-control" :class="isMobileNoValid ? '': 'border border-2 border-danger'"  @input="numberFormat('mobileNo')" @keypress="checkForNonNumericals($event)" @keyup="showWarningInFields('MobileNo'),showWarningsAbove()" @paste="showWarningInFields('MobileNo'),showWarningsAbove()"
                                                                    id="mobile"  :placeholder="translatedLangData('mobile-number','Mobile Number')+'*'" maxlength="10" v-model="form.mobileNo" :disabled="isOtpApiCalled">
                                                                <!-- <div class="call-icon"><i class="fa-solid fa-phone"></i></div> -->
                                                               
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="showValidationError && form.mobileNo == ''" class="mt-2">
                                                    <p class="wrong-message">&#8226; {{ translatedLangData('please-enter-mobile-number','Please Enter Mobile Number.') }}</p>
                                                </div>
                                                <div v-if="showValidationError && form.mobileNo?.length < 10 && form.mobileNo !=''" class="mt-2">
                                                    <p class="wrong-message">&#8226; {{ translatedLangData('mobile-number-should-be-of-10-digits','Mobile Number Should Be Of 10 Digits.') }}</p>
                                                </div>
                                                <div class="mak-gin mo-number-right">
                                                    <span v-if="timerRunning"
                                                        style="margin-right: 10px;color: white;">{{
                                                            formatTime(minutes) + ':' +
                                                            formatTime(seconds) }}</span>
                                                    <button @click.prevent="sendOtpCall()" type="button"
                                                    :disabled="loadingOtp || timerRunning || !isMobileNoValid || this.form.mobileNo==''" id="otp-btn"
                                                        class="thm-btn me-2 thm-boder-btn otp-btn get-otp-btn">
                                                        <span v-if="!loadingOtp">{{ resendOtp ? translatedLangData('resend-otp','Resend OTP') : translatedLangData('get-otp',"Get OTP")}}</span>
                                                        <div v-if="loadingOtp" class="text-center" id="Otp_loader">
                                                            <div class="spinner-border" role="status">
                                                                <span class="visually-hidden">{{translatedLangData('loading','Loading')}}...</span>
                                                            </div>
                                                        </div>
                                                    </button>
                                                </div>

                                            </div>

                                            <div class="mak-gin password-inpt mt-2" v-if="isOtpApiCalled">
                                                <input type="tel" class="form-control input-boxes" name="otp" maxlength="6" :class="this.isOtpApiCalled && this.form.otp=='' ? 'border border-2 border-danger': ''" :placeholder="translatedLangData('enter-otp','Enter OTP')+'*'"
                                                @input="numberFormat('otp')" @keypress="checkForNonNumericals($event)" @keyup="showWarningInFields('Otp'),showWarningsAbove()" id="otp-input" v-model="form.otp" required="">
                                                <div v-if="showValidationError && form.otp==''" class="mt-2">
                                                    <p class="wrong-message">&#8226; {{ translatedLangData('please-enter-otp','Please Enter OTP') }}</p>
                                                </div>
                                            </div>

                                        <div class="mak-gin password-inpt">
                                            <input type="text" class="form-control input-boxes" maxlength="25" id="inputPassword2" :disabled="isDisabled" @input="filterSpaces($event,'userName'),handleInputUserID($event,'userName')"
                                        :placeholder="translatedLangData('user-id','User ID')+'*'" v-model="form.userName" :class="(this.form.otp!='' && this.form.userName=='') || (!this.isUserNameValid && this.form.userName!='')  ? 'border border-2 border-danger': ''" @keyup="showWarningInFields('UserName'),showWarningsAbove()" @paste="showWarningInFields('UserName'),showWarningsAbove()">
                                            <div v-if="showValidationError && form.otp!='' && form.userName == ''" class="mt-2">
                                                <p class="wrong-message">&#8226; {{ translatedLangData('please-enter-user-id','Please Enter User ID') }}</p>
                                            </div>
                                            <div v-if="showValidationError && !isUserNameValid && form.userName!=''" class="mt-2">
                                                <p class="wrong-message">&#8226; {{ translatedLangData('user-id-alphabets-numbers-and-between-6-to-25-characters','User ID Must Contain Alphabets And Numbers And Between 6 To 25 Characters') }}</p>
                                            </div>
                                            <div class="call-icon"><i class="fa-regular fa-user"></i></div>
                                        </div>

                                        <div class="mak-gin password-inpt">
                                                <input :type="form.passwordVisible?'text':'password'" class="form-control toggle-password" name="password" maxlength="20"
                                                    id="password" :placeholder="translatedLangData('password','Password')+'*'" v-model="form.password" :disabled="isDisabled" @input="filterSpaces($event,'password'),handleInputUserID($event,'password')" :class="isPasswordFieldValid ? '': 'border border-2 border-danger'" @keyup="showWarningInFields('PasswordField'),showWarningsAbove()" @paste="showWarningInFields('PasswordField'),showWarningsAbove()"
                                                    aria-describedby="password">

                                                    <div class="score-hide-show">
                                                        <img loading="lazy" @click="form.passwordVisible=!form.passwordVisible" class="score-hide-icon" src="@/assets/images/score-visible.webp"
                                                         v-if="form.passwordVisible" alt="img">
                                                        <img loading="lazy" v-else @click="form.passwordVisible=!form.passwordVisible" class="score-hide-icon" src="@/assets/images/score-hide-icon.webp"  alt="img">
                                                    </div>

                                                <div v-if="showValidationError && isOtpApiCalled && form.otp && form.password == ''" class="mt-2">
                                                    <p class="wrong-message">&#8226; {{ translatedLangData('please-enter-password','Please Enter Password') }}</p>
                                                </div>
                                                <div v-if="showValidationError && form.password.length<8 && form.password!=''" class="mt-2">
                                                    <p class="wrong-message">&#8226; {{ translatedLangData('pass-min-8-max-20','Password Must Be Of Minimum 8 Characters And Maximum 20 Characters.') }}</p>
                                                </div>
                                                <div v-if="showValidationError && !/^(?=.*?[A-Z])(?=.*?[0-9])(?!.*\s)[A-Za-z0-9]*$/.test(form.password) && form.password!=''" class="mt-2">
                                                    <p class="wrong-message">&#8226; {{ translatedLangData('pass-alpha-num-1-caps','Password Must Contains Alphabets, Numbers And Atleast 1 In Capital Case.') }}</p>
                                                </div>
                                            </div>
                                            <div class="mak-gin password-inpt">
                                                <input :type="form.confirmPassVisible?'text':'password'" class="form-control toggle-password" maxlength="20"
                                                    id="confirm_password" v-model="form.confirmPassword" :disabled="isDisabled" @input="filterSpaces($event,'confirmPassword'),handleInputUserID($event,'confirmPassword')" :class="isConfirmPasswordFieldValid || this.form.password === this.form.confirmPassword ? '': 'border border-2 border-danger'" @keyup="showWarningInFields('ConfirmPasswordField'),showWarningsAbove()" @paste="showWarningInFields('ConfirmPasswordField'),showWarningsAbove()"
                                                    name="confirm_password" :placeholder="translatedLangData('confirm-password','Confirm Password')+'*'"
                                                    aria-describedby="password">
                                                    <div class="score-hide-show">
                                                        <img loading="lazy" @click="form.confirmPassVisible=!form.confirmPassVisible" class="score-hide-icon" src="@/assets/images/score-visible.webp"
                                                        v-if="form.confirmPassVisible" alt="img">
                                                        <img loading="lazy" v-else @click="form.confirmPassVisible=!form.confirmPassVisible" class="score-hide-icon" src="@/assets/images/score-hide-icon.webp"  alt="img">
                                                    </div>

                                                <div v-if="showValidationError && form.password && form.confirmPassword==''"
                                                    class="mt-2">
                                                    <p class="wrong-message">&#8226; {{ translatedLangData('please-enter-confirm-password','Please Enter Confirm Password') }}</p>
                                                </div>
                                                <div v-if="showValidationError && form.password !== form.confirmPassword && form.confirmPassword!=''"
                                                    class="mt-2">
                                                    <p class="wrong-message">&#8226; {{ translatedLangData('password-conf-pas-not-matched','Password And Confirm Password Not Matched.') }}</p>
                                                </div>
                                            </div>
                                        <div class="mak-gin password-inpt">
                                            <input type="tel" class="form-control " id="refer_code" @input="referCodeValidation"
                                            :placeholder="translatedLangData('refer-code','Refer code')" v-model="form.referCode" :disabled="checkReferUrl">
                                            <div class="call-icon"><img src="@/assets/images/signup-refer.webp"></div>
                                        </div>
                                        <input type="hidden" id="isDownLine" value="">
                                        <button :disabled="loadingSignUp || !isFormValid" @click="signUpCall()" :class="!isFormValid ? 'disabled':''" type="submit"
                                            class="btn thm-but" id="submitBtn">
                                            <span v-if="!loadingSignUp">{{ translatedLangData('register','Register')}}</span>
                                            <div v-if="loadingSignUp" class="text-center" id="Otp_loader">
                                                <div class="spinner-border" role="status">
                                                    <span class="visually-hidden">{{ translatedLangData('loading','Loading') }}...</span>
                                                </div>
                                            </div>
                                        </button>
                                        <div class="login-flow-heading">
                                            <p>{{ translatedLangData('get-your-ready-made-id-from-whatsapp','Get Your Ready-Made ID From Whatsapp')}}</p>
                                        </div>
                                        <div class="whatsapp-btn Continue-with pt-0 mb-0 pb-0">
                                            
                                            <div v-if="siteSettings?.social_link && siteSettings?.social_link?.whatsappno1"
                                                class="button-whatsapp  mt-0 pt-0 mb-0 pb-0">
                                                <a :href="'//wa.me/' + siteSettings?.social_link?.whatsappno1"
                                                    class="btn-whatsapp blinking" target="_blank">
                                                    <button type="button" class="btn thm-but" id="regWhatsApp">
                                                        <img loading="lazy" src="@/assets/images/whatsApp-icon.webp" alt="whatsApp-icon">
                                                        <span class="blinking">{{ translatedLangData('whatsapp-now','Whatsapp Now')}}</span>
                                                    </button> 
                                                </a>
                                            </div>
                                        </div>
                                        <p class="forpass-in">
                                            <span>{{ translatedLangData('already-have-an-account','Already have an account?')}}</span>
                                            <a ref="open_login_modal"
                                                href="#login-btn" data-bs-toggle="modal" @click="refreshData">{{ translatedLangData('log-in','Log in')}}</a>
                                            </p>
                                    </div>
                                </div>
                            </div>
                            <div class="login-body-rgt">
                                <div class="banner-side-img-sec">
                                    <div class="slider-model-pic">
                                        <div class="slider-model-pic">
                                            <img loading="lazy" src="@/assets/images/sl-lc-one-layer-two.webp" alt="layer-two" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapGetters } from 'vuex';
import api from '../services/api';
import * as apiName from '../services/urls';
import BannerCarousel from '@/shared/components/banner/BannerCarousel.vue';
import { Modal } from "bootstrap"

export default {
    name: "SignUp",
    inject:['translatedLangData'],
    data() {
        return {
            form: {
                code: this.siteSettings && this.siteSettings.country_code ? this.siteSettings.country_code : "91",
                mobileNo: "",
                otp: '',
                userName: "",
                password: "",
                confirmPassword: "",
                passwordVisible:false,
                confirmPassVisible:false,
                referCode: this.getQueryParam("refer_code", window.location.href),
            },
            showValidationError: false,
            showErrorMobile: false,
            passwordValidation: false,
            loadingSignUp: false,
            loadingOtp: false,
            isOtpApiCalled: false,
            resendOtp: false,
            timerRunning: false,
            minutes: 2,
            seconds: 0,
            untouchedMobileNo: true,
            untouchedOtp: true,
            untouchedUserName: true,
            untouchedPasswordField: true,
            untouchedConfirmPasswordField: true,
            isDisabled:true,
            hasUrlReferer: false
        };
    },
    computed: {
        ...mapGetters({
            siteSettings: 'siteSettings'
        }),
        siteSettings() {
            return this.$store.getters.siteSettings;
        },
        domainName() {
            return this.$store.getters.domainName;
        },
        isFormValid() {
            return this.getOtp() && this.form.otp!=='' &&this.form.otp?.length==6 && this.isUserNameValid && this.isPasswordFieldValid && this.form.confirmPassword == this.form.password && this.form.userName;
        },
        isMobileNoValid() {
            return this.untouchedMobileNo || this.form.mobileNo.length === 10;
        },
        isUserNameValid() {
            return this.untouchedUserName || (
                this.form.userName.length >= 6 &&
                this.form.userName.length <= 25 &&
                /^(?=.*?[A-Za-z])(?=.*?[0-9])(?!.*\s)[A-Za-z0-9]{6,25}$/.test(this.form.userName)
            );
        },
        isPasswordFieldValid() {
            return this.untouchedPasswordField || (
                this.form.password.length >= 8 &&
                this.form.password.length <= 20 &&
                /^(?=.*?[A-Z])(?=.*?[0-9])(?!.*\s)[A-Za-z0-9]{8,20}$/.test(this.form.password)
            );
        },
        isConfirmPasswordFieldValid() {
            return this.untouchedConfirmPasswordField || (
                this.form.confirmPassword.length >= 8 &&
                this.form.confirmPassword.length <= 20 &&
                /^(?=.*?[A-Z])(?=.*?[0-9])(?!.*\s)[A-Za-z0-9]{8,20}$/.test(this.form.confirmPassword)
            );
        },
        checkReferUrl() {
            return this.hasUrlReferer || this.isDisabled;
        }
    },
    created() {
        if(this.$route.name == 'sign-up' && this.siteSettings && this.siteSettings?.business_type == 1){
            this.$router.push({ name: 'login' })
        }
        if((window.location.href).includes('refer_code')) {
            this.hasUrlReferer = true;
        }
    },
    mounted(){
        if(this.$route.name == 'sign-up' && !this.checkIsLogin && this.siteSettings?.business_type == 2){
            setTimeout(()=> {
                let loginModal = new Modal('#register-btn');
                loginModal.show();
            }, 1000)
            this.$router.push({name: 'Home'});
        }
        else if(this.$route.name == 'sign-up' && (this.checkIsLogin || this.siteSettings?.business_type == 1)){
            this.$router.push({name: 'Home'});
        }
    },
    methods: {
        referCodeValidation(event) {
            this.form.referCode = event.target.value.replace(/\D/g, '');
        },
        handleInputUserID(event,prop) {
            this.form[prop] = event.target.value.replace(/[^\w]/g, '').replace(/_/g, '').slice(0, 25);
            const containsSpaces = /\s/.test(this.form[prop]);
            if (containsSpaces) {
                this.form[prop] = this.form[prop].replace(/\s/g, '');
            }
            event.target.value = this.form[prop].slice(0, 25);
        },
        filterSpaces(event,prop) {
            const sanitized = event.target.value.replace(/[^\w !@#$%^&*()+\-*/]/g, '');
            this.form[prop] = sanitized;
            const containsSpaces = /\s/.test(this.form[prop]);            
            if (containsSpaces) {
                this.form[prop] = this.form[prop].replace(/\s/g, '');
            }
        },  
        showWarningsAbove(){
            var regex = /^(?=.*?[A-Z])(?=.*?[0-9])(?!.*\s)[A-Za-z0-9]*$/;
            this.showValidationError = false;
            if (!this.isMobileNoValid) {
                this.showValidationError = true;
            }
            if (this.isOtpApiCalled && this.form.otp=='') {
                this.showValidationError = true;
            }
            if (this.form.otp!='' && this.form.userName=='') {
                this.showValidationError = true;            
            }
            if(!this.isUserNameValid && this.form.userName!=''){
                this.showValidationError = true;
            }
            if (this.form.otp!='' && this.form.password=='') {
                this.showValidationError = true;            
            } 
            if(this.form.password.length<8 && this.form.password!=''){
                this.showValidationError = true;
            }
            if(!regex.test(this.form.password) && this.form.password!=''){
                this.showValidationError = true;
            }
            if (this.form.password && this.form.confirmPassword=='') {
                this.showValidationError = true;            
            }            
            if(this.form.password !== this.form.confirmPassword && this.form.confirmPassword!=''){
                this.showValidationError = true;
            }
        },       
        showWarningInFields(prop) {
            this[`untouched${prop}`] = false
        },
        numberFormat(prop) {
            if (this.form[prop] != '') {
                let stringValue=''
                if(typeof this.form[prop]!='string'){
                    stringValue = this.form[prop].toString();
                }
                else{
                    stringValue = this.form[prop]
                }
                stringValue = stringValue.replace(/[^0-9]/g, '');
                stringValue = stringValue.replace(/[+-]/g, ''); // Replace '+' and '-' characters with an empty string
                if(typeof this.form[prop]!='string'){
                    this.form[prop] = stringValue === '' ? '' : parseInt(stringValue);
                }
                else{
                    this.form[prop] = stringValue
                }                
            }
        },
        checkForNonNumericals(event) {
            if (event.key.toLowerCase() === 'e') {
                event.preventDefault();
            }
            if (event.key === '-' || event.key === '+' || event.key === '.') {
                event.preventDefault();
            }
        },
        getBonusSettings() {
            api.get(apiName.GET_BONUS_SETTINGS + "?domain=" + window.location.hostname).then(response => {
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0]);
                        }
                        else {
                            let respBonusData = response.data.data
                            this.bonusData = {};
                            respBonusData.forEach(item => {
                                this.bonusData[item.bonusType] = item;
                            })
                        }
                    }
                }
            }).catch(error => {
                this.$store.dispatch('setGlobalLoader', false);
                if (error) {
                    this.$emit('error-modal', error[0]);
                }
            });
        },
        checkIsLogin() {
            return this.$store.getters.isAuthenticated;
        },
       
        refreshData() {
            this.form = {
                code:'91',
                otp: '',
                userName: "",
                password: "",
                confirmPassword: "",
                mobileNo: "",
                passwordVisible:false,
                confirmPassVisible:false,
                referCode: '',
            },
                this.showValidationError = false,
                this.showErrorMobile = false,
                this.passwordValidation = false,
                this.isOtpApiCalled = false,
                this.resendOtp = false,
                this.resendOtpTimer = 0,
                this.timerRunning = false,
                this.minutes = 0,
                this.seconds = 0,
                this.untouchedMobileNo= true;
            this.untouchedOtp= true;
            this.untouchedUserName= true;
            this.untouchedPasswordField= true;
            this.untouchedConfirmPasswordField= true;
            this.isDisabled=true;
        },
       
        sendOtpCall() {
            if (!this.loadingOtp) {
                if (this.form.mobileNo == "") {
                    this.showErrorMobile = true;
                }
                else {
                    this.sendOtpServiceCall();
                }
            }
        },
      async sendOtpServiceCall() {
            this.loadingOtp = true;
            let data = {
                "mobile_number": this.form.mobileNo,
                "cncode": this.form.code,
            };
            api.post(apiName.SEND_OTP, data).then(response => {
                this.loadingOtp = false;
                if (response) {
                    if (response.status == 200) {
                        this.isOtpApiCalled = true;
                        this.showErrorMobile = false;
                        this.isDisabled = false;
                        this.showSuccessModalFunc(response.data.message)
                        this.resendOtp = true;
                        this.timerRunning = true;
                        this.minutes = 2;
                        this.seconds = 0
                        this.setResendTimer();
                    }
                    else if (response.status == 0) {
                    }
                }
            }).catch(error => {
                this.loadingOtp = false;
                this.showErrorModalFunc(error[0])
            });
        },


        setResendTimer() {

            const countdown = () => {
                if (this.minutes === 0 && this.seconds === 0) {
                    this.timerRunning = false;
                    // Timer has expired, you can perform any action here
                } else {
                    if (this.seconds === 0) {
                        this.minutes--;
                        this.seconds = 59;
                    } else {
                        this.seconds--;
                    }
                    setTimeout(countdown, 1000);
                }
            };

            setTimeout(countdown, 1000);
        },

        formatTime(value) {
            return value < 10 ? `0${value}` : value;
        },



        signUpCall() {
            if (!this.loadingSignUp) {
                var regex = /^(?=.*?[A-Z])(?=.*?[0-9])(?!.*\s)[A-Za-z0-9]*$/;
                let otp = this.getOtp();
                this.showValidationError = false;
                if (this.form.mobileNo == "") {
                    this.showErrorMobile = true;
                }
                else if (otp.length != 6 || this.form.userName == '' || this.form.password == '' || this.form.confirmPassword == '') {
                    this.showValidationError = true;
                }
                else if (this.form.password != this.form.confirmPassword) {
                    this.showValidationError = true;
                }
                else if (!regex.test(this.form.password)) {
                    this.showValidationError = true;
                }
                else {
                    this.signUpServiceCall();
                }
            }
        },
        async signUpServiceCall() {
            let isDownLine = this.getQueryParam("downline", window.location.href);
            this.loadingSignUp = true;
            let data = {
                "userid": this.form.userName,
                "phone": this.form.mobileNo,
                "cncode": this.form.code,
                "password": this.form.password,
                "otp": this.getOtp(),
                "isDownLine": isDownLine ? isDownLine : false,
                "refer_code": this.form.referCode,
            };
            api.post(apiName.REGISTER, data).then(response => {
                this.loadingSignUp = false;
                if (response) {
                    if (response.status == 200) {
                        if (response.data.status == 0) {
                            this.showErrorModalFunc(response.data.debug[0]);
                        }
                        else {
                            this.showSuccessModalFunc(response.data.message);
                            this.refreshData();
                            this.$refs.open_login_modal.click();
                            
                        }
                    }
                }
            }).catch(error => {
                this.loadingSignUp = false;
                this.showErrorModalFunc(error[0]);
            });
        },
        getQueryParam(paramName, url) {
            if (!url) url = location.href;
            paramName = paramName.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
            var regexS = "[\\?&]" + paramName + "=([^&#]*)";
            var regex = new RegExp(regexS);
            var results = regex.exec(url);
            return results == null ? null : results[1];
        },
        getOtp() {
            return this.form.otp.toString().replaceAll(',', '');
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        }

    },
    components: { BannerCarousel }
};
</script>