<template>

    <div>

    <div class="modal-dialog onload-modal  modal-dialog-centered">
        <div class="modal-content">
            <div class="recent-bank-sec">
                <div class="recent-bank">
                    <div class="bank-icon">
                        <img loading="lazy" src="@/assets/images/right-icon.webp" alt="right-icon">
                    </div>
                    <div class="bank-con">
                        <h6>Success!</h6>
                        <span>{{ message }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="modal fade show-success-messege" id="betplaced-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog onload-modal">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="recent-bank bet-success-wrapper">
                        <div class="placed-right-icon">
                            <img loading="lazy" src="@/assets/images/check.webp" alt="">
                        </div>
                        <div class="show-success-messege-cmn-h">
                            <h6>Bet Placed Successfully!!</h6>
                            <span>{{ message }}</span>
                        </div>
                        <div class="close-success-btn">
                            <button type="button" data-bs-dismiss="modal" aria-label="Close">
                                <img src="@/assets/images/close-bet.webp" alt="">
                            </button>
                        </div>
                    </div>
                </div>    
            </div>
        </div>
    </div> -->

</div>

</template>
<script>
export default {
    name: "SuccessModal",
    props: ['message'],
}
</script>