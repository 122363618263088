const ProfileModule = () => import(/* webpackChunkName: "profile-module" */ './views/Module.vue');
const Profile = () => import(/* webpackChunkName: "profile" */ './views/Profile.vue');

const ProfileRoutes = {
    path: '/',
    component: ProfileModule,
    children: [
        {
            path: 'profile/:type',
            name: 'profile',
            component: Profile
        }
    ],
}   

export default ProfileRoutes;