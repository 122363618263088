
export const USER_PROFILE='api/user-profile'
export const LOGOUT_API= '/api/logout'
export const AUTH_API= '/api/auth-status'
export const  GET_MY_MARKETS='api/get-my-markets';

export const SITE_SETTINGS='api/site-settings';
export const AUTH_STATUS='api/auth-status';
export const GET_FAVOURITE_LIST='api/favorite-list';
export const WALLET_BALANCE='wallet';
export const AUTH_REFRESH='api/auth-refresh';
export const GET_SPORTS= 'v1/sports/management/getSport';
export const SEARCH='api/search';
export const DREAM_CASINO='api/dreamcasino/load-game/';
export const GET_LIVE_POCKER_URL='api/get-live-poker-url/';
export const GET_LOCKED_EARNED_BONUS = 'api/get-locked-earn-bonus';
export const UPDATE_WALLET_TYPE = 'api/wallet-switch';
export const GET_BONUS_LOGS= 'api/get-bonus-log';
export const GET_SITE_RULES= 'api/get-site-rules';
export const SUBSCRIBE_NOTIFICATION= 'api/subscribe-notification';
export const NOTIFICATIONS_LIST= 'get/all/notification/history';
export const MATCHES_BY_SPORT_ID = 'v1/events/matches/';
export const getVersionStatus= 'api/site-status-version';
export const v1_SITE_SETTINGS= 'api/v1-site-settings';