const BetsModule = () => import(/* webpackChunkName: "bets-module" */ './views/Module.vue'); 
const Bets = () => import(/* webpackChunkName: "bets" */ './views/Bets.vue');
const Statements = () => import(/* webpackChunkName: "statements" */ './views/Statements.vue');
const ProfitLoss = () => import(/* webpackChunkName: "profit-loss" */ './views/ProfitLoss.vue');
const UnsettledBet = () => import(/* webpackChunkName: "profit-loss" */ './views/UnsettleBet.vue');
const MarketAnalyasis = () => import(/* webpackChunkName: "profit-loss" */ './views/MarketAnalysis.vue');
const ProfitLossDetail = () => import(/* webpackChunkName: "profit-loss" */ './views/ProfitLossDetail.vue');
const BetHistory = () => import(/* webpackChunkName: "profit-loss" */ './views/BetHistory.vue');
// const MultiMarket = () => import(/* webpackChunkName: "profit-loss" */ './views/MultiMarket.vue');

const BetsRoutes =   {
    path: '/',
    component: BetsModule,
    children: [
      {
        path: 'bets',
        name: 'bets',
        component: Bets
      },
      {
        path: 'statements',
        name: 'statements',
        component: Statements
      },
      {
        path: 'profit-loss',
        name: 'profit-loss',
        component: ProfitLoss
      },
      {
        path: 'unsettled-bets',
        name: 'unsettled-bets',
        component: UnsettledBet
      },
      {
        path: 'market-analysis',
        name: 'market-analysis',
        component: MarketAnalyasis
      },
      {
        path: 'profit-loss-detail',
        name: 'ProfitLossDetail',
        component: ProfitLossDetail
      },
      {
        path: 'bet-history',
        name: 'BetHistory',
        component: BetHistory
      },
      // {
      //   path: 'multi-markets',
      //   name: 'multi-markets',
      //   component: MultiMarket
      // },
    ],
  }

export default BetsRoutes;