<template>
    <div class="banner-side-img-sec">
        <Carousel class="banner-navigation-side-img" :wrapAround="true" :items-to-show="1">
            <Slide class="owl-carousel owl-theme">
                <div class="item">
                    <div class="slider-model-pic">
                        <div class="slider-model-pic">
                            <img loading="lazy" src="@/assets/images/sl-lc-one-layer-two.webp" alt="sl-lc-one-layer-two" />
                        </div>
                    </div>
                </div>
            </Slide>

            <Slide class="owl-carousel owl-theme">
                <div class="item">
                    <div class="slider-model-pic">
                        <div class="slider-model-pic">
                            <img loading="lazy" src="@/assets/images/sl-c-one-layer-two.webp" alt="sl-c-one-layer-two" />
                        </div>
                    </div>
                </div>
            </Slide>

            <Slide class="owl-carousel owl-theme">
                <div class="item">
                    <div class="slider-model-pic">
                        <div class="slider-model-pic">
                            <img loading="lazy" src="@/assets/images/Betbaba-Spor-1.webp" alt="Betbaba-Spor-1" />
                        </div>
                    </div>
                </div>
            </Slide>

            <template #addons>
                <Navigation />
            </template>
        </Carousel>
    </div>
</template>
<script>

import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

export default {
    name: 'BannerCarousel',
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation
    }
}
</script>