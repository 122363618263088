<template>
    <div class="rule-model-sec">
        <div class="modal rules-modal" id="rules">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="rule-heading">
                        <div class="modal-header">
                            <h4 class="modal-title">{{translatedLangData('rules', 'Rules')}}</h4>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$emit('close-click')"><i
                                    class="fa-solid fa-xmark"></i></button>
                        </div>
                    </div>
                    <div class="rules-container">
                        <div class="modal-body">
                            <template v-for="(rule, r_index, rule_num) in siteRuleData" :key="r_index">
                                <template v-if="r_index == 'General'">                            
                                    <div class="menu-details-list all-rule" v-html="rule"></div>
                                </template>
                            </template>
                        </div>
                        <div class="rules-modal-footer"><button class="btn btn-danger" data-bs-dismiss="modal" aria-label="Close" @click="$emit('close-click')">{{translatedLangData('close', 'Close')}}</button></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import api from '@/shared/services/api';
import * as apiName from '@/shared/services/urls';
import { Modal } from 'bootstrap';

export default {
    name: "RulesModal",
    inject: ['translatedLangData'],
    props: ['error-modal', 'success-modal'],
    data() {
        return {
            siteRuleData: null,
            loading:false
        }
    },
    mounted(){
        this.getRules()
    },
    emits: ['close-click'],
    methods: {
        getRules() {
            this.loading = true;
            if(this.siteRuleData) {
                return;
            }
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            api.get(apiName.GET_SITE_RULES, { headers }).then(response => {
                this.loading = false;
                this.$store.dispatch('setGlobalLoader', false);
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0]);
                        }
                        else {
                            let ruleResp = response.data.data;
                            this.siteRuleData = {}
                            ruleResp.forEach(item => {
                                this.siteRuleData[item.type] = item.content;
                            })
                            let ruleModal = new Modal('#rules');
                            ruleModal.show();
                        }
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.$emit('error-modal', error[0]);
                }
            });
        },
    }
}
</script>