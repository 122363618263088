const WalletModule = () => import(/* webpackChunkName: "wallet-module" */ './views/Module.vue');
const Wallet = () => import(/* webpackChunkName: "wallet" */ './views/Wallet.vue');
const WalletDeposit = () => import(/* webpackChunkName: "wallet-deposit" */ './views/WalletDeposit.vue');
const WalletHistory = () => import(/* webpackChunkName: "wallet-history" */ './views/WalletHistory.vue');
const WalletHistoryDetail = () => import(/* webpackChunkName: "wallet-history-detail" */ './views/WalletHistoryDetail.vue');
//const PaymentMethod = () => import(/* webpackChunkName: "payment-method" */ './views/PaymentMethod.vue');
//const AddAccount = () => import(/* webpackChunkName: "add-account" */ './views/AddAccount.vue');
const WalletWithdraw = () => import(/* webpackChunkName: "add-account" */ './views/WalletWithdraw.vue');
const DepositPaymentMethod = () => import(/* webpackChunkName: "deposit-payment-method" */ './views/DepositPaymentMethod.vue');
const WalletGateway = () =>import(/* webpackChunkName: "wallet-gateway" */ './views/WalletGateway.vue')


const WalletRoutes = {
    path: '/',
    component: WalletModule,
    children: [
      {
        path: 'wallet',
        name: 'wallet',
        component: Wallet,
      },
      {
        path: 'wallet-deposit',
        name: 'wallet-deposit',
        component: WalletDeposit,
      },
      {
        path: 'wallet-history',
        name: 'wallet-history',
        component: WalletHistory,
      },
      {
        path: 'wallet-history-detail/:type',
        name: 'wallet-history-detail',
        component: WalletHistoryDetail,
      },
      // {
      //   path: 'payment-method',
      //   name: 'payment-method',
      //   component: PaymentMethod,
      // },
      // {
      //   path: 'add-account/:itemDetail',
      //   name: 'add-account',
      //   component: AddAccount,
      // },
      {
        path: 'wallet-withdraw',
        name: 'wallet-withdraw',
        component: WalletWithdraw,
      },
      {
        path: 'deposit-payment-method',
        name: 'deposit-payment-method',
        component: DepositPaymentMethod,
      },
      {
        path: 'wallet-gateway',
        name: 'wallet-gateway',
        component: WalletGateway,
      }
    ],
}

export default WalletRoutes;