<template>
    <div class="mobile-offcanvass">
        <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample11" aria-labelledby="offcanvasExampleLabel">
        <div ref="close_modal_ref" style="display: none" data-bs-dismiss="offcanvas"></div>
           <SideMenu  @close-modal="closeModal()" @openRulesMOdal="rulesModal = true" />
        </div>
    </div>
    <RulesModal v-if="rulesModal" @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" @close-click="closeRulesModal" />

</template>
<script>
import SideMenu from './SideMenu.vue';
import RulesModal from '@/shared/components/rules/RulesModal.vue';

export default {
    name: 'MobileSideMenu',
    components:{
        SideMenu,
        RulesModal
    },
    data() {
        return {
            rulesModal : false
        }
    },
    methods:{
        closeRulesModal() {
            this.rulesModal = false;
        },
        closeModal(){
            this.$refs.close_modal_ref.click();
        }
    }
}
</script>