const GamesModule = () => import(/* webpackChunkName: "games-module" */ './views/Module.vue');
const Casino = () => import(/* webpackChunkName: "casino" */ './views/Casino.vue');
const IntCasino = () => import(/* webpackChunkName: "casino" */ './views/IntCasino.vue');

const GamesRoutes = {
    path: '/',
    component: GamesModule,
    children: [
        {
            path: 'casino',
            name: 'casino',
            component: Casino
        },
        {
            // path: 'int-casino/:providerId?/:providerName?',
            path: 'int-casino/:provider?',
            name: 'int-casino',
            component: IntCasino,
            props: true
        }
    ],
}

export default GamesRoutes;