<template>
    <div class="modal fade " id="informationModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog  modal-dialog-centered information-modal-wrapper">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="thm-heading">
                        <h4>{{translatedLangData('bonus-rules', 'Bonus Rules')}}</h4>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                        <i class="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="back-modal p-4">
                        <div class="mene-details information-modal-sec">
                            <template v-for="(rule, r_index, rule_num) in siteRuleData" :key="r_index">
                                <template v-if="r_index != 'General'">
                                    <strong>{{ rule_num }}. {{ r_index }}</strong>
                                    <div class="menu-details-list all-rule" v-html="rule"></div>
                                </template>
                            </template>
                    </div>
                    <div class="back-modal" v-if="!siteRuleData || !Object.keys(siteRuleData).length">
                        <div class="mene-details information-modal-sec">
                            <div class="menu-details-list">
                                {{translatedLangData('no-rules-available', 'No rules available.')}}
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="conditions-sec">
                    <a data-bs-toggle="modal" data-bs-target="#rulesModal"  class="conditions-btn thm-but">Terms and Conditions</a>
                </div> -->
            </div>
        </div>
    </div>
</div>

<!-------Weekly Reward Modal--------->
<div class="modal fade " id="weeklyrewardModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered loginModal-sec registerModal-sec">
         <div class="modal-content">
            <div class="modal-header container-close">
               <div class="thm-heading">
                  <h3>Weekly Reward</h3>
               </div>
               <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body language-modal">
               <div class="weekly-reward">
                  <div class="row">
                     <div class="col-6">
                        <div class="weekly-reward-con">
                           <div class="weekly-reward-header animate">
                              <h3>Platinum</h3>
                              <div class="reward-bg1 reward-bg"></div>
                              <div class="reward-bg2 reward-bg"></div>
                              <div class="reward-bg3 reward-bg"></div>
                           </div>
                           <div class="weekly-reward-body">
                              <h3><span>3%</span> Weekly <br> lossback bonus</h3>
                              <div class="weekly-reward-footer platinum-footer">
                                 <span>Turnover: 1CR</span>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-6">
                        <div class="weekly-reward-con gold-thm">
                           <div class="weekly-reward-header animate">
                              <h3>GOLD</h3>
                              <div class="reward-bg1 reward-bg"></div>
                              <div class="reward-bg2 reward-bg"></div>
                              <div class="reward-bg3 reward-bg"></div>

                           </div>
                           <div class="weekly-reward-body">
                              <h3><span>4%</span> Weekly <br> lossback bonus</h3>
                              <div class="weekly-reward-footer platinum-footer">
                                 <span>Turnover : 1k</span>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-6">
                        <div class="weekly-reward-con silver-thm">
                           <div class="weekly-reward-header animate">
                              <h3>Silver</h3>
                              <div class="reward-bg1 reward-bg"></div>
                              <div class="reward-bg2 reward-bg"></div>
                              <div class="reward-bg3 reward-bg"></div>

                           </div>
                           <div class="weekly-reward-body">
                              <h3><span>1%</span> Weekly <br> lossback bonus</h3>
                              <div class="weekly-reward-footer platinum-footer">
                                 <span>Turnover : 1k</span>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-6">
                        <div class="weekly-reward-con bule-thm">
                           <div class="weekly-reward-header animate">
                              <h3>BULE</h3>
                              <div class="reward-bg1 reward-bg"></div>
                              <div class="reward-bg2 reward-bg"></div>
                              <div class="reward-bg3 reward-bg"></div>
                           </div>
                           <div class="weekly-reward-body">
                              <h3>Climb up the ladder to <br> avail loyalty benefits</h3>
                              <div class="weekly-reward-footer platinum-footer">
                                 <span>Turnover : 1k</span>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
    </div>
<!-------Weekly Reward Modal--------->
</template>
<script>
export default {
    name: "BonusInformation",
    inject: ['translatedLangData'],
    props: ['siteRuleData'],
};
</script>

<style>
.menu-details-list.all-rule p {
    padding: 10px 0px;
}
</style>