<template>
    <div class="modal fade" id="locked-bonus-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
        <div class="modal-dialog modal-lg modal-dialog modal-dialog-centered information-modal-wrapper">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="thm-heading">
                        <h4>{{translatedLangData('locked-bonus','Locked Bonus')}}</h4>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$emit('closeBonusLogs')">
                        <i class="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="locked-bonus-sec">
                        <div class="locked-bonus-list">
                            <ul>
                                <li v-for="(bonus, b_index) in bonusLogs" :key="b_index">
                                    <div class="locked-bonus-card">
                                        <div class="bonus-lft-bx"><span>₹ {{ !isNaN(getTotalRolling(bonus.lock_bonus, bonus.earn_bonus, bonus.rolling_amount)) ? getTotalRolling(bonus.lock_bonus, bonus.earn_bonus, bonus.rolling_amount) : 0 }}</span>{{translatedLangData('bonus','Bonus')}}</div>
                                        <div class="bonus-rit-bx">
                                            <strong>{{translatedLangData('deposit-bonus','Deposit Bonus')}}</strong>
                                            <div class="bonus-progress-bx">
                                                <div class="progress" role="progressbar" aria-label="Success example"
                                                    aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                    <div class="progress-bar bg-success" :style="{width: getUsedBonus(bonus.lock_bonus, bonus.earn_bonus) + '%'}"></div>
                                                </div>
                                            </div>
                                            <div class="rolling-amount">
                                                <span>{{translatedLangData('rolling-amount','Rolling Amount')}}:- ₹ {{ bonus.rolling_amount }}</span><span>{{translatedLangData('earn-bonus','Earn Bonus')}} :- ₹ {{ bonus.earn_bonus }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li v-if="!bonusLogs || !bonusLogs.length">
                                    <div class="locked-bonus-card">
                                       <NoDataFound></NoDataFound>
                                    </div>
                                </li>                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import api from '@/shared/services/api';
import * as apiName from '@/shared/services/urls';
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import { Modal } from 'bootstrap';
import NoDataFound from '../no-data/NoDataFound.vue';

export default {
    name: 'LockedBonus',
    inject: ['translatedLangData'],
    data() {
        return {
            bonusLogs: null
        }
    },
    components: {
        NoDataFound
    },
    mounted() {
        this.getBonusLogs();
    },
    methods: {
        getBonusLogs() {
            let authToken = this.$cookies.get(AUTH_TOKEN);
			let headers = {
				'Authorization': `Bearer ${authToken}`
			}
            api.get(apiName.GET_BONUS_LOGS, {headers}).then(response => {
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0]);
                        }
                        else {
                            this.bonusLogs = response.data.data.data;
                            let bonusLogModal = new Modal('#locked-bonus-modal');
                            bonusLogModal.show();
                        }
                    }
                }
            }).catch(error => {
                this.$store.dispatch('setGlobalLoader', false);
                if (error) {
                    this.$emit('error-modal', error[0]);
                }
            });
        },
        getUsedBonus(locked, earned) {
            return parseFloat((earned/locked)*100).toFixed(2);
        },
        getTotalRolling(locked, earned, rolling) {
            return parseFloat((earned/locked)*rolling).toFixed(2);
        }
    }
}
</script>

<style>
.bonus-progress-bx span {
    font-size: 10px;
}
</style>